.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Sansita+Swashed&display=swap");

svg {
  font-family: "Sansita Swashed", sans-serif;
  width: 100%;
  height: 100%;
}

svg text {
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: indianred;
  font-size: 50px;
}

@keyframes stroke {
  0% {
    fill: rgba(204, 12, 12, 0);
    stroke: indianred;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  20% {
    fill: rgba(204, 12, 12, 0);
    stroke: Indianred;
  }

  50% {
    fill: rgba(204, 12, 12, 0);
    stroke: Indianred;
    stroke-width: 3;
  }

  100% {
    fill: indianred;
    stroke: rgba(255, 17, 15, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.wrapper {
  background-color: #FFFFFF
}

;
